.category {
    
}

.search_element {
    margin: 0 auto;
    min-width: 350px;
    max-width: 1300px;
    height: 90vh;
	margin-top: 60px;
}

.search_result {
    text-align: center;
    margin: 10px 0px;
}

.search_addEventButton {  
    background-color: rgb(245, 159, 0) !important;
    color: white !important;
    margin-left: 30px !important;
}

.search_Link {
    text-decoration: none !important;
}

.search_result > span {
    font-size: 20px;
    font-weight: 700;
    color: var(--mylives_color);
}

.search_eventList {
    
}

/*画面幅800px以下の設定
------------------------------------------------------------------------------------------------------------------------------------------------------*/
@media screen and (max-width:800px){
    .userPage_header {
        margin-top: 10px;
        margin-bottom: 25px;
    }

    .userPage_avatar {
        margin-right: 10px;
    }
    
    .userPage_username{
        font-size: 15px;
    }
    
    .userPage_userid{
        font-size: 12px;   
    }

    .userPage_counts{ 
        font-size: 15px;    
    }
    
}

/*画面幅480px以下の設定
------------------------------------------------------------------------------------------------------------------------------------------------------*/
@media screen and (max-width:480px){
    .userPage_header {
        margin-top: 5px;
        margin-bottom: 15px;
    }

    .userPage_avatar {
        margin-right: 5px;
    }

    .userPage_username{
        font-size: 12px;
    }
    
    .userPage_userid{
        font-size: 9px;   
    }

    .userPage_counts{ 
        font-size: 12px;    
    }
}