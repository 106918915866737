.addevent {
    
}

.addevent_element {
    width: 90%;
    margin: 0 auto;
    min-width: 350px;
    max-width: 1300px;
    height: 90vh;
	margin-top: 60px;
}

.addevent_body_form > h3 {
    margin-bottom: 0px;
}

.addevent_body_form > p {
    margin-bottom: 10px;
}

.addevent_body_form > textarea {
    resize: none;
    width:100%;
    height:150px;
}

label {
    margin-right: 10px;
}

/*画面幅800px以下の設定
------------------------------------------------------------------------------------------------------------------------------------------------------*/
@media screen and (max-width:800px){    
}

/*画面幅480px以下の設定
------------------------------------------------------------------------------------------------------------------------------------------------------*/
@media screen and (max-width:480px){
}