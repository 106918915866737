.top { 
}

.top_header {
	position: relative;
    width: 100%;
    height: 900px;
}

.top_header_nav {
    position: absolute;
    color: white;
    top: 0%;
    left: 0%;
    margin:0;
    padding:0;
}

.top_header_links {
    display: flex;
    padding-left: 0px;
    margin: 20px;
}

.top_header_link {
    padding: 10px 10px;
    list-style:none;
}

.top_header_linkto {
    transition: color 0.5s ease-out;
    color: white;
    text-decoration: none !important;
    font-size: 18px;
    font-weight: bold;
}

.top_header_linkto:hover {
    color: aquamarine;
}

.top_header_copy {
    position: absolute;
    color: white;
    top: 18%;
    left: 50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    margin:0;
    padding:0;
    font-size: 24px;
    font-weight: 900;
}

.top_header_title {
    position: absolute;
    color: white;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    margin:0;
    padding:0;
    font-size: 24px;
    font-weight: 900;
}

.top_header_image {
    width: 100%;
    height: 900px;
    object-fit:cover;
    object-position: 50% 60%;
	filter: brightness(0.3);
}

.top_header_logo {
    position: absolute;
    width: 300px;
    top: 40%;
    left: 50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    margin:0;
    padding:0;
    border-radius: 30px;
}

.top_header_search {
    position: absolute;
    top: 65%;
    left: 50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    margin:0;
    padding:0;
    width: 300px;
}

.top_header_searchbox {
}

.top_body {    
    height: 100vh;
    max-width: 1300px;
    margin: 0 auto;   
    width: 100%;
}

.top_new_review {
    min-width: 350px;
    max-width: 1300px;
    margin: 0 auto;
    margin-bottom: 50px;
    padding: 0;
}

.top_new_review > h3 {
    font-size: 36px;
    font-weight: 900;
}

.top_new_app {
    min-width: 350px;
    max-width: 1300px;
    margin: 0 auto;
    margin-bottom: 50px;
    padding: 0;
}

.top_new_app > h3 {
    font-size: 36px;
    font-weight: 900;
}

.top_new_app_list {
    list-style: none;
    cursor: pointer;
}

.top_new_app_list > img {
    border-radius: 20%;
    width: 60px;
    height: 60px;
    margin-left: 10px;
    margin-right: 10px;
}

.top_search_category {
    min-width: 350px;
    max-width: 1300px;
    margin: 0 auto;
    margin-bottom: 50px;
    padding: 0;
}

.top_search_category > h3 {
    font-size: 36px;
    font-weight: 900;
}

.top_header_header {
    display: none;
}


/*画面幅800px以下の設定
------------------------------------------------------------------------------------------------------------------------------------------------------*/
@media screen and (max-width:800px){
    .top_header_copy {
        width:250px;
        left: 52%;
        font-size: 24px;
        font-weight: 900;
    }

    .top_new_review > h3 {
        font-size: 24px;
        font-weight: 800;
    }    

    .top_new_app > h3 {
        font-size: 24px;
        font-weight: 800;
    }    

    .top_search_category > h3 {
        font-size: 24px;
        font-weight: 800;
    }  
}

/*画面幅480px以下の設定
------------------------------------------------------------------------------------------------------------------------------------------------------*/
@media screen and (max-width:480px){
    .top_header_copy {
        width:250px;
        top: 20%;
        left: 52%;
        font-size: 24px;
        font-weight: 900;
    }

    .top_new_review > h3 {
        font-size: 24px;
        font-weight: 700;
    }  

    .top_new_app > h3 {
        font-size: 24px;
        font-weight: 700;
    }  

    .top_search_category > h3 {
        font-size: 24px;
        font-weight: 700;
    }  

    .top_header_nav {
        display: none;
    }
    
    .top_header_header {
        display: inline;
    }

    .top_header_logo {    
        width: 250px;
    }
}