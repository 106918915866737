.categoryDetails {    
    cursor: pointer;
    position: relative;
    height: 100px;
    margin: 5px 0px;
}

.categoryDetails_category {
    position: absolute;
    color: white;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    margin:0;
    padding:0;
    font-size: 32px;
    font-weight: 900;
}

.categoryDetails_image {
    width: 100%;
    height: 100px;
    object-fit: cover;
    background-color: black;
	filter: brightness(0.5);
}
