
.search{
    display: flex;
    width: 100%;
}

form {
    width: 100%;
}

.search__bar{
    display: flex;
    padding: 5px;
    border: 1px solid lightgray;
    border-radius: 10px;
    background-color: #f0f2f5;
}
.search__bar > input {
    width: 100%;
    border: none !important;
    background-color: #f0f2f5;
}

.search__bar > input:focus {
    outline-width: 0;
}

.search__bar > input::placeholder{
    text-align: left;
    font-size: 15px;
}
.search__bar > .MuiSvgIcon-root {
    margin-right: 5px;
}