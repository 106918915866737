.personalTokenDetails {
    justify-content: center;
    padding-bottom: 5px;
}

.personalTokenDetails_body {
    padding-bottom: 35px;
}

.personalTokenDetails_body > img {
    border-radius: 10px;
    width: 100%;
    max-width: 300px;
    object-fit: contain;
    background-color: black;
}

.personalTokenDetails_isOwned {
    color: orange;
}

.personalTokenDetails_header {
    margin-top: 10px;
}

.personalTokenDetails_header > div {
    display: inline-block;
}

.personalTokenDetails_userId {
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 10px;
}

.personalTokenDetails_description {
    font-weight: 700;
}

.personalTokenDetails_add{
    height: 40px;
    margin-left: 10px;
    margin-bottom: 10px;
}

.personalTokenDetails_token {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-right: 20px;
    font-size: 12px;
}

.personalTokenDetails_planned {
    padding: 5px;
    border-radius: 5px;
    background-color: rgb(189, 189, 189);
}