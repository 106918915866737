.appDetail {    
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 5px;
}

.appDetail_logo {
    border-radius: 20%;
    max-width: 250px;
    max-height: 250px;
    width: 30vw;
    height: 30vw;
    margin-left: 10px;
    margin-right: 5%;
}

.appDetail_header {
    display:  flex;
    margin-bottom: 20px;
}

.appDetail_header_text {
    margin-top: 10px;
    width: 60vw;
}

.appDetail_header_appName {
    font-size: 24px;
    font-weight: 900;
}

.appDetail_header_store_url {
    margin-top: 10px;
    margin-bottom: 10px;
}

.appDetail_header_store_btn {
    margin-right: 10px;
    margin-bottom: 10px !important;
}

.appDetail_header_store_button {
    width: 150px;
    height: 50px;
}

.appDetail_body {
    margin: 0px 10px;
}