.addapp {
}

.addapp_element {
    min-width: 350px;
    max-width: 1300px;
    height: 90vh;
    width: 90%;
    margin: 0 auto;
	margin-top: 70px;
}

label {
    margin-right: 10px;
}



/*画面幅800px以下の設定
------------------------------------------------------------------------------------------------------------------------------------------------------*/
@media screen and (max-width:800px){    
}

/*画面幅480px以下の設定
------------------------------------------------------------------------------------------------------------------------------------------------------*/
@media screen and (max-width:480px){
}