.sidebar {
    min-width: 50px;
    padding-right: 20px;
	margin-top: 70px;
	margin-right: 40px;
}

.sidebar_mysoulIcon {
    color: var(--mysoul_color);
    font-size: 30px !important;
    margin-top: 15px;
    margin-left: 20px;
    margin-bottom: 20px;
}

.sidebar_engrave {
    background-color: var(--mysoul_color) !important;
    color: white !important;
    font-weight: 900 !important;
    border: none;
    border-radius: 25px !important;
    height: 50px !important;
    margin-top: 20px !important;
}

.sidebar_engrave:hover {
    border-color: rgb(255, 255, 255);
}

/*画面幅800px以下の設定
------------------------------------------------------------------------------------------------------------------------------------------------------*/
@media screen and (max-width:800px){
    .sidebar {
        flex: 0.15;
        min-width: 50px;
        margin-right: 0px;
    }
}