.header {
    display:  flex;
    align-items: center;
	position: fixed;
	z-index: 999;
	top: 0;
	left: 0;
	width: 100%;
	height: 60px;
    padding-top: 5px;
	box-sizing: border-box;
    border-bottom: 1px solid var(--record_planet_background);
    background-color: var(--record_planet_background);
}

.header_icon {
    margin-left: 5%;
}

.header_mysoulIconLink {
    left: 5%;
    cursor: pointer;
    color: var(--record_planet_color);
    margin: 0 auto;
    margin-top: 6px;
}
.header_mysoulIcon {
    border-radius: 5px;
    height: 45px;
}

.header_nav {
    margin-left: 5%;
    color: white;
}

.header_links {
    display: flex;
    margin: 0;
    padding-left: 0px;
}

.header_link {
    padding: 10px 10px;
    list-style:none;
}

.header_linkto {
    transition: color 0.5s ease-out;
    color: white;
    text-decoration: none !important;
    font-size: 18px;
    font-weight: bold;
    font-family: "Noto Sans Japanese";
}

.header_linkto:hover {
    color: aquamarine;
}

.header_right {
    display:  flex;
    margin:0 10px 0 auto;
}

.header_userIcon {
    cursor: pointer;
    color: var(--mylives_color);
    margin-right: 10px !important;;
}

.header_login {
    margin-right: 10px !important;;
}

.header_loginLink {    
    text-decoration: none !important;
}

.header_loginButton {    
    background-color: var(--record_planet_color) !important;
    color: white !important;
    font-weight: 900 !important;
    border: none;
    height: 40px !important;
}

.header_engrave {
    background-color: var(--record_planet_color) !important;
    color: white !important;
    font-weight: 900 !important;
    border: none;
    height: 40px !important;
    margin-right: 10px !important;;
}

.header_engrave:hover {
    border-color: rgb(255, 255, 255);
}

.header_burger {
    display: none;
}

/*画面幅800px以下の設定
------------------------------------------------------------------------------------------------------------------------------------------------------*/
@media screen and (max-width:800px){
    .header_linkto {
        font-size: 12px;
    }

    .header_loginLink {      
        font-size: 12px;
    }

    .header_icon {
        margin: 0 auto;
    }
}

/*画面幅480px以下の設定
------------------------------------------------------------------------------------------------------------------------------------------------------*/
@media screen and (max-width:480px){
    .header_right {
        display: none;
    }

    .header_nav {
        display: none;
    }

    .header_burger {
        display: inline;
    }
}