.tokenThumb {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid var(--mysoul_background);
    padding-top: 5px;
    padding-left: 10px;
    margin-bottom: 5px;
    border-radius: 5px;
    cursor: pointer;
}

.tokenThumb:hover {
    background-color: aliceblue;
}

.tokenThumb_body {
    display: flex;
    margin-bottom: 10px;
    margin-right: 20px;
}

p {
    margin: 0;
    padding: 0;
}

.tokenThumb_eventInfo {
    margin-left: 5px;
}

.tokenThumb_eventTitle {
    font-size: 15px;
    font-weight: 700;
}

.tokenThumb_eventDate {
    font-size: 12px;
}

.tokenThumb_eventPlace {
    font-size: 12px;
}
