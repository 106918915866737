.tokenDetails {    
    display:  flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 5px;
    margin-left: 5px;
    margin-right: 5px;
    max-width: 600px;
    border-radius: 10px;
    border-color: rgb(100, 100, 100);
    cursor: pointer;
}

.tokenDetails:hover {
    background-color: aliceblue;
}

.tokenDetails_avatar {
    margin-right: 10px;
}

.tokenDetails_body {
    padding-bottom: 35px;
}

.tokenDetails_body > img {
    border-radius: 10px;
    width: 100%;
    max-width: 300px;
    object-fit: contain;
    background-color: black;
}

.tokenDetails_isOwned {
    color: orange;
}

.tokenDetails_header { 
}

.tokenDetails_header > div {
    display: inline-block;
}

.tokenDetails_headerText {
    font-size: 22px;
    font-weight: 900;
}

.tokenDetails_title {
    font-size: 18px;
    font-weight: 800;
    color: blue;
}

.tokenDetails_add{
    height: 40px;
    margin-left: 10px;
    margin-bottom: 10px;
}

.tokenDetails_token {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-right: 20px;
    font-size: 12px;
}

.tokenDetails_planned {
    padding: 5px;
    border-radius: 5px;
    background-color: rgb(189, 189, 189);
}