.sidebarOption {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: color 0.1s ease-out;
    margin-left: 22px;
    height: 60px;
    text-decoration: none;
    color: black;
}

.sidebarOption:hover {
    background-color: #d9f8d9;
    border-radius: 25px;
    color: var(--mysoul_color);
}

.sidebarOption > h2 {
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    font-weight: 800;
}

.sidebarOption_active {
    color: var(--mysoul_color);
}

/*画面幅480px以下の設定
------------------------------------------------------------------------------------------------------------------------------------------------------*/
@media screen and (max-width:800px){
    .sidebarOption > h2 {
        font-size: 0px;
    }
}