.appInfo {
    
}

.appInfo_element {
    margin: 0 auto;
    min-width: 350px;
    max-width: 1000px;
    height: 90vh;
	margin-top: 60px;
}

.appInfo_addReview {
    margin-top: 40px;
    margin-left: 10px;
}

.appInfo_review {
    margin: 0px 10px;
}



/*画面幅800px以下の設定
------------------------------------------------------------------------------------------------------------------------------------------------------*/
@media screen and (max-width:800px){    
}

/*画面幅480px以下の設定
------------------------------------------------------------------------------------------------------------------------------------------------------*/
@media screen and (max-width:480px){
}