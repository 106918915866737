.about {
	position: relative;
}

.about_header_logo {
    position: absolute;
    width: 300px;
    top: 250px;
    left: 50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    margin:0;
    padding:0;
    border-radius: 30px;
}

.about_text {
    position: absolute;
    color: white;
    margin: 0 auto;
    max-width: 850px;
    width: 85vw;
    line-height: 44px;
    font-size: 22px;
    font-weight: 900;
    top: 450px;
    left: 50%;
    -ms-transform: translate(-50%,0%);
    -webkit-transform: translate(-50%,0%);
    transform: translate(-50%,0%);
    letter-spacing: 0.15em;
}

.about_text > p {
    margin-top: 20px;
    margin-bottom: 60px;
}

.about_text > h2 {
    color:deepskyblue;
}

.about_text > h3 {
    color:rgb(255, 248, 115);
}

.about_text > img {
    width: 30%;
    margin: 0 10%;
    border-radius: 100%;
}

.about_bg_image {
	position: fixed;
    top: 60px;
    width: 110%;
	filter: brightness(0.3);
}

/*画面幅800px以下の設定
------------------------------------------------------------------------------------------------------------------------------------------------------*/
@media screen and (max-width:800px){
    .about_text {
        line-height: 200%;
        font-size: 20px;
        font-weight: 800;
    }

    .about_text > p {
        margin-top: 10px;
        margin-bottom: 40px;
    }
}

/*画面幅480px以下の設定
-----------------------------------------------------------------------------------------------------------------------------------------------------*/
@media screen and (max-width:480px){
    .about_text {
        line-height: 210%;
        font-size: 18px;
    }

    .about_text > p {
        margin-top: 0px;
        margin-bottom: 40px;
    }
}